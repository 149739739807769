
  import { Component, Vue, Prop, Emit } from "vue-property-decorator";

  type TPageNumbers = {
    pageNumber: number;
    backgroundColor: string;
    color: string;
  }[];

  @Component({})
  export default class Pagination extends Vue {
    @Prop({ required: true })
    protected readonly items!: unknown[];
    @Prop({ required: true })
    protected readonly currentPageNumber = 1;

    @Emit("onPageChange")
    onPageChange(action: "next" | "prev" | number): void {
      const pages = this.$refs.pageNumber as HTMLDivElement[];

      const pageNumberToScrollTo =
        action === "next"
          ? this.currentPageNumber + 1
          : action === "prev"
          ? this.currentPageNumber - 1
          : action;

      const pageToScrollTo = pages.find(
        page => +page.innerText === pageNumberToScrollTo
      );

      pageToScrollTo?.scrollIntoView();
    }

    handleMouseWheel(e: WheelEvent) {
      const target = e.target as HTMLElement;
      target.scrollLeft += e.deltaY * 2;
    }

    get pageNumbers(): TPageNumbers {
      const itemsPerPage = 10;
      const length = Math.ceil(this.items.length / itemsPerPage);

      return Array.from({ length }, (_item, i) => {
        const pageNumber = i + 1;
        return {
          pageNumber,
          backgroundColor:
            pageNumber === this.currentPageNumber ? "#8D00D8" : "transparent",
          color: pageNumber === this.currentPageNumber ? "white" : "#A0A7B9"
        };
      });
    }
  }
