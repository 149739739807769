
  import { Component, Prop, Vue, Watch } from "vue-property-decorator";
  import downloadExcel from "vue-json-excel";
  import Pagination from "./Pagination.vue";

  interface KeyItem {
    id: string;
    label: string;
  }

  @Component({
    components: { downloadExcel, Pagination }
  })
  export default class Table extends Vue {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageItems: unknown[] = [];
    itemsPerPage = 10;
    currentPageNumber = 1;

    @Prop({ required: true })
    readonly title!: string;

    @Prop({ required: true })
    readonly fields!: KeyItem[];

    @Prop({ required: true })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly items!: any[];

    @Prop({ required: false, default: true })
    readonly downloable: boolean = true;

    get downloadFields() {
      return this.fields.reduce((downloadFields: any, field) => {
        downloadFields[field.label] = field.id;
        return downloadFields;
      }, {});
    }

    handlePageChange(action: "next" | "prev" | number): void {
      const { currentPageNumber, itemsPerPage } = this;

      if (action === "next") {
        const isLastPage =
          currentPageNumber * itemsPerPage >= this.items.length - 1;

        !isLastPage && this.currentPageNumber++;
      }

      if (action === "prev") {
        const isFirstPage = currentPageNumber <= 1;

        !isFirstPage && this.currentPageNumber--;
      }

      if (typeof action === "number") this.currentPageNumber = action;

      const start = (this.currentPageNumber - 1) * this.itemsPerPage;
      const end = this.currentPageNumber * this.itemsPerPage;
      this.pageItems = this.items.slice(start, end);
    }

    @Watch("items", { immediate: true })
    onItemsChange(items: any[]) {
      this.pageItems = items?.slice(0, 10) || [];
    }
  }
